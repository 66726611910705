import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Main from "./pages/Main";
import About from "./pages/About";
import Disclaimer from "./pages/Disclaimer";
import Policy from "./pages/Policy";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import CookiePopup from "./components/CookiePopup";

import "./theme/style.css";
import discImg from "./theme/img/webp/21.webp";
import { Typography } from "@mui/material";

function App() {
  return (
    <BrowserRouter>
      <Header />

      <Box component="main" sx={{ p: 0 }}>
        <Routes>
          <Route path="/">
            <Route index element={<Main />} />
            <Route path="about" element={<About />} />
            <Route path="disclaimer" element={<Disclaimer />} />
            <Route path="privacy-policy" element={<Policy />} />
          </Route>
        </Routes>
      </Box>

      <Snackbar open={true} autoHideDuration={6000}>
        <Alert
          severity="info"
          icon={false}
          sx={{position: 'fixed', bottom: '66px',
            background: "rgba(255, 255, 255, 0.2)",
            fontWeight: 700,
            p: "0 0.5em"
          }}
        >
          <Box
            sx={{
              border: "3px solid #ffffff",
              borderRadius: "50%",
              fontSize: "10px",
              width: "36px",
              height: "36px",
              p: ".9em .4em .3em",
              display: 'inline-block'
            }}
          >
            <img src={discImg} width="26" alt="" />
          </Box>
          <Typography component="span" sx={{ m: "0 0.5em", color: "#fff" }}>
            No Real Money
          </Typography>
        </Alert>
      </Snackbar>
      <Snackbar open={true} autoHideDuration={6000}>
        <Alert
          severity="info"
          icon={false}
          sx={{
            background: "rgba(255, 255, 255, 0.2)",
            fontWeight: 700,
            p: "0 0.5em",
            color: 'white'
          }}
        >
        <Typography component="span" sx={{ m: "0 0.5em", color: "#fff" }}>
            Free Online Games
        </Typography>
        </Alert>
      </Snackbar>
      <CookiePopup />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
