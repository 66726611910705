import React, { useEffect, useMemo, useState } from "react";
import ImageCard from "../Card";
import { Box } from "@mui/material";
import games from '../../data/games'


function GamesBlock({filter}) {
  const [gamesList, setGamesList] = useState([])

  useEffect(() => {
    if(typeof filter === 'number') {
      const newList = games.slice(0, filter);
      setGamesList(newList)
    }
    if(!filter) setGamesList(games.sort(() => 0.5 - Math.random()))

  }, [filter])


 
 

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: '1200px',
        m: "3em auto",
        textAlign: "center",
        boxSizing: "border-box",
      }}
    >
      {gamesList?.map((item, index) => (
        <ImageCard
          key={item.title + index}
          img={item.img}
          title={item.title}
          src={item.url}
        />
      ))}
    </Box>
  );
}

export default GamesBlock;
