import { Box, Typography } from "@mui/material";
import React from "react";

function ContactText() {
  return (
    <section>
    <Box
      sx={{
        m: "0 auto 5em",
        textAlign: "center",
      }}
    >
      <Typography variant="h3" sx={{ color: "white" }}>Have a question?</Typography>
      
      <Typography
          component="p"
          sx={{ m: "0 auto", pt: "2em", fontSize: "18px", maxWidth: "1000px", textAlign: 'left', color: 'white' }}
        >Feel free to reach out to us via email, and our dedicated team will be more than happy to assist you. Your inquiries are important to us, and we're here to provide the support you need. Contact us at <b>contact@247istechipokies.com.</b>

      </Typography>
    </Box>

    </section>
  )
}

export default ContactText