import React from 'react'
import DocumentMeta from "react-document-meta";
import PolicyText from '../components/contentSections/Policy';

const meta = {
    title: "Privacy policy | 247istechipokies - popular games",
    description: "This Privacy Policy",
  };


function Policy() {
  return (
    <DocumentMeta {...meta}>
        <PolicyText />
    </DocumentMeta>
  )
}

export default Policy