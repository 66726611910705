import React from "react";
import { Box, Card, Typography } from "@mui/material";
import CelebrationIcon from '@mui/icons-material/Celebration';
import PeopleIcon from "@mui/icons-material/People";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";

function WhyUs() {
  return (
    <section className="why-us">
      <Box
        sx={{
          width: "90%",
          m: "0 auto",
          textAlign: "center",
        }}
      >
        <Typography variant="h3" sx={{ color: "white" }}>
          Why us
        </Typography>
      </Box>
      <Box sx={{ width: "100%", m: "0 auto", textAlign: "center" }}>
        <Card
          sx={{
            width: "300px",
            minHeight: "200px",
            m: "2em 1em",
            textAlign: "center",
            display: "inline-block",
            background: "transparent",
          }}
          variant="outlined"
        >
          <Typography
            sx={{ mb: "1em", fontWeight: 700, fontSize: 25, color: "white" }}
          >
            Diverse Game Selection
          </Typography>
          <hr/>
          
          <Typography
            sx={{ mt: "2em", fontSize: 16, color: "white" }}
          >
            Indulge in a vast assortment of games. Our site boasts a rich selection of entertaining games designed to cater to a wide range of preferences, ensuring constant engagement.
          </Typography>
        </Card>
        <Card
          sx={{
            width: "300px",
            minHeight: "200px",
            m: "2em 1em",
            textAlign: "center",
            display: "inline-block",
            background: "transparent",
          }}
          variant="outlined"
        >
          <Typography
            sx={{ mb: "1em", fontWeight: 700, fontSize: 25, color: "white" }}
          >
            Endless Free Gaming
          </Typography>
          <hr/>
          
          <Typography
            sx={{ mt: "2em", fontSize: 16, color: "white" }}
          >
            Experience unlimited gaming enjoyment without the need to open your wallet. Our site offers an expansive array of games and features, all available free with no hidden costs or surprises.
          </Typography>
        </Card>
        <Card
          sx={{
            width: "300px",
            minHeight: "200px",
            m: "2em 1em",
            textAlign: "center",
            display: "inline-block",
            background: "transparent",
          }}
          variant="outlined"
        >
          <Typography
            sx={{ mb: "1em", fontWeight: 700, fontSize: 25, color: "white" }}
          >
            24/7 Customer Support
          </Typography>
          <hr/>
          
          <Typography
            sx={{ mt: "2em", fontSize: 16, color: "white" }}
          >
            Whenever you require assistance, our site is here for you, day and night. With 24/7 customer support, whether you have questions our dedicated support team is at your service around the clock.
          </Typography>
        </Card>
      </Box>
    </section>
  );
}

export default WhyUs;
