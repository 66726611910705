import React from "react";
import DocumentMeta from "react-document-meta";
import AboutSection from '../components/contentSections/About';
import WhyUs from "../components/contentSections/WhyUs";
import ContactText from "../components/contentSections/ContactText";
import TextSection from "../components/contentSections/TextSection";

const meta = {
  title: "About | 247istechipokies - popular games",
  description: "Step into the eeriest and most spine-tingling social casino site in town, 247istechipokies.com! If you're a fan of all things mysterious and spooky, you've arrived at your ultimate destination.",
};

function About() {
  return (
    <DocumentMeta {...meta}>
      <AboutSection />
      <TextSection/>
      <WhyUs />
      <ContactText />
    </DocumentMeta>
  );
}

export default About;
