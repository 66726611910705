import React from "react";
import { Box, Typography } from "@mui/material";

function TextSection() {
  return (
    <section id="text">
      <Box sx={{ width: "100%", m: "1em auto", textAlign: "center" }}>
        <Typography variant="h4" sx={{ m: "0 auto", color: "white" }}>
        Unveiling Istechipokies
        </Typography>
      </Box>
      <Box sx={{ width: "100%", m: "1em auto", textAlign: "left" }}>
        <Typography
          component="p"
          sx={{ m: "0 auto", p: "2em 0", fontSize: "18px", maxWidth: "80%", color: 'white' }}
        >Welcome to Istechipokies, the ultimate haven for those who relish social casino gaming with a captivatingly enigmatic twist! Our platform introduces a gaming encounter that is both unique and slightly eerie, unlike any other you've ever encountered. We take immense pride in delivering a peerless social gaming adventure, leaving an indelible mark on our users.
          <br />
          <br />
          <Typography sx={{ textAlign: "center", fontSize: "1.3em" }}>
          Why Hesitate? Dive into the Mystery at 247istechipokies.com Today!
          </Typography>
          Exclusively Mysterious Gaming Experience:<br/>
          Immerse yourself in a social gaming environment that is truly exceptional and shrouded in mystery. Unveil the unexpected and immerse yourself in the hauntingly enigmatic atmosphere that distinguishes us from the rest.
          <br />
          <br />
          <Typography sx={{ textAlign: "center", fontSize: "1.3em" }}>
          A Diverse Array of Games:
          </Typography>
          Discover a vast selection of games, including slots, blackjack, roulette, and more. Our extensive game catalog ensures that you'll always find the ideal game to suit your preferences.
          <br />
          <br />
          <Typography sx={{ textAlign: "center", fontSize: "1.3em" }}>
          Join a Vibrant Social Gaming Community:
          </Typography>
          Engage with like-minded gamers within our social gaming community, where you can compete against and challenge one another. The spirit of competition and camaraderie awaits you.
          <br />
          <br />
          So, why prolong the excitement? Unlock the thrill of social gaming with a touch of mystery by playing with us at 247istechipokies.com. An exceptional gaming experience is just a click away!
        </Typography>
      </Box>
    </section>
  );
}

export default TextSection;
