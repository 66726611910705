import React from "react";
import { Box, Card, Typography } from "@mui/material";

import img from "../../theme/img/1.jpg";
function Disclaimer() {
  return (
    <section>
      <Card
        sx={{
          width: "90%",
          maxHeight: "350px",
          m: "3em auto",
          textAlign: "center",
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
          borderRadius: "2em",
        }}
        variant="outlined"
      >
        <img src={img} width="100%" style={{}} alt="company promo" />
      </Card>
      <Box
        sx={{
          m: "2em auto 5em",
          textAlign: "center",
          background: "white",
          p: "1.5em 2em",
          borderRadius: "1em",
        }}
      >
        <Typography variant="h1" sx={{ color: "white" }}>
          Disclaimer
        </Typography>

        <Typography
          component="p"
          sx={{
            m: "0 auto",
            pt: "2em",
            fontSize: "18px",
            textAlign: "left",
          }}
        >
          Welcome to our social pokies platform. Before you embark on your
          journey within our site and engage with any of the games, collectively
          referred to as the "Services," we kindly request that you review and
          consent to the terms and conditions outlined below:
          <br />
          <br />
          <b>General Information:</b>
          <br />
          To set the stage, it's imperative to clarify that we do not function
          as a gambling entity, and our Services are not intended for real-money
          gambling activities. Our Services encompass an array of virtual
          pokies-style entertainment games, entirely separated from real-money
          wagers or prizes. Regarding personal information, rest assured that we
          collect only what is necessary to deliver the Services, and we treat
          any shared information in strict accordance with our privacy policy.
          It's crucial to understand that your use of the Services is at your
          discretion. Please note that we cannot guarantee uninterrupted
          availability or error-free performance, and we cannot be held
          responsible for potential malfunctions, disruptions, or damage to your
          computer systems or mobile devices arising from your interaction with
          the Services. Additionally, it's vital to acknowledge that we retain
          the right to modify, suspend, or terminate the Services at our
          discretion, without the obligation to provide prior notice.
          <br />
          <br />
          <b>Responsibility for Your Service Usage:</b>
          <br />
          As the user, you are solely responsible for all activities conducted
          under your account and must ensure that your usage of the Services
          fully complies with relevant laws and regulations. We greatly
          appreciate your commitment not to employ the Services for unlawful or
          fraudulent purposes and your agreement not to disrupt the enjoyment of
          the Services by other users. Furthermore, it's crucial to recognize
          that any virtual currency provided as part of the Services has no
          real-world value and is non-redeemable for cash or other forms of
          rewards. The sale or transfer of your account or any virtual currency
          acquired through the Services is only permissible with our explicit
          authorization.
          <br />
          <br />
          <b>Intellectual Property:</b>
          <br />
          All intellectual property rights related to the Services and their
          content, excluding user-generated content, are either owned by us or
          licensed to us. The usage of any intellectual property rights
          pertaining to the Services or their content is strictly prohibited
          without our prior written consent.
          <br />
          <br />
          <b>Governing Law:</b>
          <br />
          These terms and conditions shall be governed by the laws of the
          country in which you reside or use the Services, without consideration
          for principles of conflicts of law. By utilizing the Services, you
          agree to exclusively submit to the jurisdiction of the courts located
          in the country of your residence or use of the Services for the
          resolution of any disputes stemming from your utilization of the
          Services.
          <br />
          <br />
          <b>Acceptance of these terms and conditions:</b>
          <br />
          Your utilization of the Services signifies your understanding and
          agreement with these terms and conditions. If you do not concur with
          these terms and conditions, you are not authorized to employ the
          Services.
        </Typography>
      </Box>
    </section>
  );
}

export default Disclaimer;
