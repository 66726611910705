import React from "react";
import { Box, Card, Typography } from "@mui/material";
import img from "../../theme/img/webp/1.webp";

function About() {
  return (
    <section id="about">
      <Card
        sx={{
          width: "90%",
          maxHeight: '350px',
          m: "3em auto",
          textAlign: "center",
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
          borderRadius: '2em'
        }}
        variant="outlined"
      >
        <img
          src={img}
          width="100%"
          style={{  }}
          alt="company promo"
        />
      </Card>
      <Box sx={{ width: "100%", m: "1em auto", textAlign: "center" }}>
        <Typography
          variant="h1"
          sx={{ m: "0 auto", fontWeight: 700, color: "white" }}
        >
          About us
        </Typography>
      </Box>
      <Box sx={{ width: "100%", m: "1em auto", textAlign: "center" }}>
        <Typography
          component="p"
          sx={{ m: "0 auto", p: "2em 0", fontSize: "18px", maxWidth: "80%", color: 'white' }}
        >
          Step into the eeriest and most spine-tingling social casino site in
          town, 247istechipokies.com! If you're a fan of all things mysterious
          and spooky, you've arrived at your ultimate destination. Our site is
          wholly dedicated to providing you with a gaming experience that is not
          only thrilling but also profoundly entertaining.
          <br />
          <br />
          Here at 247istechipokies.com, we offer an extensive array of games
          designed to send shivers down your spine. Whether you fancy slots,
          blackjack, or roulette, we have a wide selection to cater to your
          eerie preferences. Our games come with a haunting twist, featuring
          chilling sound effects and graphics that are sure to keep you on the
          edge of your seat.
          <br />
          <br />
          Yet, it's not just about the games; 247istechipokies.com takes great
          pride in ensuring that our users experience a safe and secure gaming
          environment. Our site is fully licensed and rigorously regulated,
          guaranteeing that you engage in fair and trustworthy gameplay.
          <br />
          <br />
          But let's return to the fun! Our mission is to build a community of
          like-minded spooky gamers, and we accomplish this by delivering an
          unrivaled social gaming experience. You can engage in lively chats
          with fellow players, share your favorite games, and even forge new
          friendships along the way.
          <br />
          <br />
          And speaking of friends, we have a few eerie characters who are
          eagerly waiting to meet you! Allow us to introduce Crypt, our site's
          spooky mascot, here to guide you through your gaming adventure. Crypt
          is always on the lookout for new players to join the
          247istechipokies.com family, so don't be startled if he pops up while
          you're enjoying your preferred games.
        </Typography>
      </Box>
    </section>
  );
}

export default About;
