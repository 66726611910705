import React from "react";
import { Box, Card, Typography } from "@mui/material";
import img from "../../theme/img/1.jpg";

function Hero() {
  return (
    <section className="hero">
      <Box
        sx={{
          p: '1em 3em 5em',
          borderRadius: "1em",
          textAlign: "center",
          display: "flex",
          flexDirection: 'column',
          justifyContent: "center",
          alignItems: "center",
          textAlign: 'left',
          color: 'white'
        }}
        variant="outlined"
      >
      <Card
        sx={{
          width: "100%",
          maxHeight: '500px',
          m: "0 auto",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: '2em'
        }}
        variant="outlined"
      >
        <img
          src={img}
          width="100%"
          style={{ objectFit: "cover" }}
          alt="company promo"
        />
      </Card>
        <Typography variant="h1" sx={{fontWeight: 700, textTransform: 'capitalize', pt: '1em' }}>
        PLAY TOP GAMES HERE
        </Typography>
        <Typography component="p" sx={{ maxWidth: '850px', fontSize: '1.2em', pt: '2em'}}>
        Embark on a thrilling gaming journey with Istechipokies. Join us today to indulge in a diverse array of immensely popular  games, all available for free!
        </Typography>
      </Box>
    </section>
  );
}

export default Hero;
