import React from 'react'
import DocumentMeta from "react-document-meta";
import Hero from "../components/contentSections/Hero";
import GamesBlock from '../components/contentSections/GamesBlock';
import WhyUs from '../components/contentSections/WhyUs';
import Typography from "@mui/material/Typography";
import TextSection from '../components/contentSections/TextSection';

const meta = {
    title: "Home Page | 247istechipokies - popular games",
    description: "mbark on a thrilling gaming journey with Istechipokies. Join us today to indulge in a diverse array of immensely popular games, all available for free!",
    canonical: '247istechipokies.com',
    meta: {
        charset: 'utf-8',
    }
  };

function Main() {
  return (
    <DocumentMeta {...meta}>
        <Hero />
        <Typography variant="h3" sx={{fontWeight: 700, textTransform: 'capitalize', textAlign: 'center', color: 'white' }}>
        Games
        </Typography>
        <GamesBlock />
        <WhyUs />
        <TextSection />
    </DocumentMeta>
  )
}

export default Main