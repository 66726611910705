// All game icons should be imported
import mergeFruit from "../theme/img/webp/2.webp";
import gardenTails from "../theme/img/webp/garden-tails.webp";
import jewelsBlitz from "../theme/img/webp/jewelsblitz.webp";
import TheMergestKingdom from "../theme/img/webp/themergestkingdom.webp"
import candyRiddles from "../theme/img/webp/candyriddles.webp"
import trafficTour from "../theme/img/webp/traffictour.webp"
import bubbleGame3 from "../theme/img/webp/bubblegame3.webp"
import mahjongDimensions from "../theme/img/webp/mahjongdimensions.webp"
import dreamPetLink from "../theme/img/webp/dreampetlink.webp"
import ParkourBlock5 from "../theme/img/webp/parkour-block-5.webp"
import krisMahjong from "../theme/img/webp/kris-mahjong.webp"
import parkourBlock2 from "../theme/img/webp/parkour-block-2.webp"
import geometryJump from "../theme/img/webp/geometry-jump.webp"
import pou from "../theme/img/webp/pou.webp"
import snowRider3D from "../theme/img/webp/snow-rider-3d.webp"
import wormsZoneSlitherySnake from "../theme/img/webp/worms-zone-a-slithery-snake.webp"

const games = [
  {
    title: "Merge Fruit",
    img: mergeFruit,
    url: "https://html5.gamedistribution.com/rvvASMiM/2dee9d404697435aa76111eb4015e1d5/index.html?gd_zone_config=eyJwYXJlbnRVUkwiOiJodHRwczovL2h0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tLzJkZWU5ZDQwNDY5NzQzNWFhNzYxMTFlYjQwMTVlMWQ1LyIsInBhcmVudERvbWFpbiI6Imh0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tIiwidG9wRG9tYWluIjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJoYXNJbXByZXNzaW9uIjp0cnVlLCJsb2FkZXJFbmFibGVkIjp0cnVlLCJob3N0IjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJ2ZXJzaW9uIjoiMS41LjE2In0%253D",
  },
  {
    title: "Garden Tails",
    img: gardenTails,
    url: "https://sg.gamedistribution.com/games/garden-tales/gamesites/99999/locale/?gd_sdk_referrer_url=https%3A%2F%2Fhtml5.gamedistribution.com%2Ffa29efc197d04cb1bb3074b8255f817f%2F&gd_zone_config=eyJwYXJlbnRVUkwiOiJodHRwczovL2h0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tL2ZhMjllZmMxOTdkMDRjYjFiYjMwNzRiODI1NWY4MTdmLyIsInBhcmVudERvbWFpbiI6Imh0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tIiwidG9wRG9tYWluIjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJoYXNJbXByZXNzaW9uIjpmYWxzZSwibG9hZGVyRW5hYmxlZCI6dHJ1ZSwiaG9zdCI6Imh0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tIiwidmVyc2lvbiI6IjEuNS4xNiJ9",
  },
  {
    title: "Jewels Blitz 5",
    img: jewelsBlitz,
    url: "https://html5.gamedistribution.com/rvvASMiM/0dd92ae2a7214f289608049167f2ebd4/index.html?gd_zone_config=eyJwYXJlbnRVUkwiOiJodHRwczovL2h0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tLzBkZDkyYWUyYTcyMTRmMjg5NjA4MDQ5MTY3ZjJlYmQ0LyIsInBhcmVudERvbWFpbiI6Imh0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tIiwidG9wRG9tYWluIjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJoYXNJbXByZXNzaW9uIjp0cnVlLCJsb2FkZXJFbmFibGVkIjp0cnVlLCJob3N0IjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJ2ZXJzaW9uIjoiMS41LjE2In0%253D",
  },
  { title: "The Mergest Kingdom", img: TheMergestKingdom, url: "https://html5.gamedistribution.com/rvvASMiM/b5203bfef51242c39690c761321d769f/index.html?gd_zone_config=eyJwYXJlbnRVUkwiOiJodHRwczovL2h0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tL2I1MjAzYmZlZjUxMjQyYzM5NjkwYzc2MTMyMWQ3NjlmLyIsInBhcmVudERvbWFpbiI6Imh0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tIiwidG9wRG9tYWluIjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJoYXNJbXByZXNzaW9uIjp0cnVlLCJsb2FkZXJFbmFibGVkIjp0cnVlLCJob3N0IjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJ2ZXJzaW9uIjoiMS41LjE2In0%253D" },
  { title: "Candy Riddles: Free Match 3 Puzzle", img: candyRiddles, url: "https://riddles.gcdn.co/publish/html5/?source=gdcom&gd_sdk_referrer_url=https://html5.gamedistribution.com/639df47033804f18bf39e113ef29f4fa/" },
  { title: "Traffic Tour", img: trafficTour, url: "https://html5.gamedistribution.com/rvvASMiM/ce3d2279ff684021a68b534e397f1122/index.html?gd_zone_config=eyJwYXJlbnRVUkwiOiJodHRwczovL2h0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tL2NlM2QyMjc5ZmY2ODQwMjFhNjhiNTM0ZTM5N2YxMTIyLyIsInBhcmVudERvbWFpbiI6Imh0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tIiwidG9wRG9tYWluIjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJoYXNJbXByZXNzaW9uIjp0cnVlLCJsb2FkZXJFbmFibGVkIjp0cnVlLCJob3N0IjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJ2ZXJzaW9uIjoiMS41LjE2In0%253D" },
  { title: "Bubble Game 3", img: bubbleGame3, url: "https://html5.gamedistribution.com/rvvASMiM/27673bc45d2e4b27b7cd24e422f7c257/index.html?gd_zone_config=eyJwYXJlbnRVUkwiOiJodHRwczovL2h0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tLzI3NjczYmM0NWQyZTRiMjdiN2NkMjRlNDIyZjdjMjU3LyIsInBhcmVudERvbWFpbiI6Imh0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tIiwidG9wRG9tYWluIjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJoYXNJbXByZXNzaW9uIjp0cnVlLCJsb2FkZXJFbmFibGVkIjp0cnVlLCJob3N0IjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJ2ZXJzaW9uIjoiMS41LjE2In0%253D" },
  { title: "Mahjong Dimensions", img: mahjongDimensions, url: "https://gamedistribution.arkadiumarena.com/arenaapi/game/mahjongg-dimensions/html5?gd_sdk_referrer_url=https%3A%2F%2Fhtml5.gamedistribution.com%2F0b726d29dabd4bdb8623f56fb8a22464%2F&gd_zone_config=eyJwYXJlbnRVUkwiOiJodHRwczovL2h0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tLzBiNzI2ZDI5ZGFiZDRiZGI4NjIzZjU2ZmI4YTIyNDY0LyIsInBhcmVudERvbWFpbiI6Imh0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tIiwidG9wRG9tYWluIjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJoYXNJbXByZXNzaW9uIjpmYWxzZSwibG9hZGVyRW5hYmxlZCI6dHJ1ZSwiaG9zdCI6Imh0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tIiwidmVyc2lvbiI6IjEuNS4xNiJ9" },
  { title: "Dream Pet Link", img: dreamPetLink, url: "https://html5.gamedistribution.com/rvvASMiM/f9de399f8d324adc828279e73f43951c/index.html?gd_zone_config=eyJwYXJlbnRVUkwiOiJodHRwczovL2h0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tL2Y5ZGUzOTlmOGQzMjRhZGM4MjgyNzllNzNmNDM5NTFjLyIsInBhcmVudERvbWFpbiI6Imh0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tIiwidG9wRG9tYWluIjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJoYXNJbXByZXNzaW9uIjp0cnVlLCJsb2FkZXJFbmFibGVkIjp0cnVlLCJob3N0IjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJ2ZXJzaW9uIjoiMS41LjE2In0%253D" },
  { title: "Parkour Block 5", img: ParkourBlock5, url: "https://html5.gamedistribution.com/rvvASMiM/e18b34214db742c4a97cc80650fc02dd/index.html?gd_zone_config=eyJwYXJlbnRVUkwiOiJodHRwczovL2h0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tL2UxOGIzNDIxNGRiNzQyYzRhOTdjYzgwNjUwZmMwMmRkLyIsInBhcmVudERvbWFpbiI6Imh0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tIiwidG9wRG9tYWluIjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJoYXNJbXByZXNzaW9uIjp0cnVlLCJsb2FkZXJFbmFibGVkIjp0cnVlLCJob3N0IjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJ2ZXJzaW9uIjoiMS41LjE2In0%253D" },
  { title: "Kris Mahjong", img: krisMahjong, url: "https://html5.gamedistribution.com/rvvASMiM/5ed3b1c510c3400db0ef580c60cbcdca/index.html?gd_zone_config=eyJwYXJlbnRVUkwiOiJodHRwczovL2h0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tLzVlZDNiMWM1MTBjMzQwMGRiMGVmNTgwYzYwY2JjZGNhLyIsInBhcmVudERvbWFpbiI6Imh0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tIiwidG9wRG9tYWluIjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJoYXNJbXByZXNzaW9uIjp0cnVlLCJsb2FkZXJFbmFibGVkIjp0cnVlLCJob3N0IjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJ2ZXJzaW9uIjoiMS41LjE2In0%253D" },
  { title: "Parkour Block 2", img: parkourBlock2, url: "https://html5.gamedistribution.com/rvvASMiM/cb6ef4e519594dda80934b30f2baaff9/index.html?gd_zone_config=eyJwYXJlbnRVUkwiOiJodHRwczovL2h0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tL2NiNmVmNGU1MTk1OTRkZGE4MDkzNGIzMGYyYmFhZmY5LyIsInBhcmVudERvbWFpbiI6Imh0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tIiwidG9wRG9tYWluIjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJoYXNJbXByZXNzaW9uIjp0cnVlLCJsb2FkZXJFbmFibGVkIjp0cnVlLCJob3N0IjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJ2ZXJzaW9uIjoiMS41LjE2In0%253D" },
  { title: "Geometry Jump", img: geometryJump, url: "https://html5.gamedistribution.com/rvvASMiM/9b634d67ddd6407c95effd409b947a76/index.html?gd_zone_config=eyJwYXJlbnRVUkwiOiJodHRwczovL2h0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tLzliNjM0ZDY3ZGRkNjQwN2M5NWVmZmQ0MDliOTQ3YTc2LyIsInBhcmVudERvbWFpbiI6Imh0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tIiwidG9wRG9tYWluIjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJoYXNJbXByZXNzaW9uIjp0cnVlLCJsb2FkZXJFbmFibGVkIjp0cnVlLCJob3N0IjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJ2ZXJzaW9uIjoiMS41LjE2In0%253D" },
  { title: "Pou", img: pou, url: "https://html5.gamedistribution.com/rvvASMiM/d1362abc246f43bdb5c26643aec2b61b/index.html?gd_zone_config=eyJwYXJlbnRVUkwiOiJodHRwczovL2h0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tL2QxMzYyYWJjMjQ2ZjQzYmRiNWMyNjY0M2FlYzJiNjFiLyIsInBhcmVudERvbWFpbiI6Imh0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tIiwidG9wRG9tYWluIjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJoYXNJbXByZXNzaW9uIjp0cnVlLCJsb2FkZXJFbmFibGVkIjp0cnVlLCJob3N0IjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJ2ZXJzaW9uIjoiMS41LjE2In0%253D" },
  { title: "Snow Rider 3D", img: snowRider3D, url: "https://html5.gamedistribution.com/rvvASMiM/3b79a8537ebc414fb4f9672a9b8c68c8/index.html?gd_zone_config=eyJwYXJlbnRVUkwiOiJodHRwczovL2h0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tLzNiNzlhODUzN2ViYzQxNGZiNGY5NjcyYTliOGM2OGM4LyIsInBhcmVudERvbWFpbiI6Imh0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tIiwidG9wRG9tYWluIjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJoYXNJbXByZXNzaW9uIjp0cnVlLCJsb2FkZXJFbmFibGVkIjp0cnVlLCJob3N0IjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJ2ZXJzaW9uIjoiMS41LjE2In0%253D" },
  { title: "Worms Zone a Slithery Snake", img: wormsZoneSlitherySnake, url: "https://html5.gamedistribution.com/rvvASMiM/5dd0b18fb81d49da82ff459f08737390/index.html?gd_zone_config=eyJwYXJlbnRVUkwiOiJodHRwczovL2h0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tLzVkZDBiMThmYjgxZDQ5ZGE4MmZmNDU5ZjA4NzM3MzkwLyIsInBhcmVudERvbWFpbiI6Imh0bWw1LmdhbWVkaXN0cmlidXRpb24uY29tIiwidG9wRG9tYWluIjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJoYXNJbXByZXNzaW9uIjp0cnVlLCJsb2FkZXJFbmFibGVkIjp0cnVlLCJob3N0IjoiaHRtbDUuZ2FtZWRpc3RyaWJ1dGlvbi5jb20iLCJ2ZXJzaW9uIjoiMS41LjE2In0%253D" },
];



export default games
// games.sort(() => 0.5 - Math.random()).slice(0, 5)